// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/v2/theming/

// Font path is used to include ionicons,
$font-path: "../assets/fonts";


// The app direction is used to include
// rtl styles in your app. For more info, please see:
// http://ionicframework.com/docs/theming/rtl-support/
$app-direction: ltr;


// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/v2/theming/overriding-ionic-variables/

$range-md-knob-width: 26px;
$range-md-hit-width: 24px;
$range-md-knob-min-background-color: primary;

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

$colors: (
  primary:    #0579ba,
  secondary:  #32db64,
  danger:     #f53d3d,
  light:      #f4f4f4,
  dark:       #222,
  warning:    #dc9a00,
);


// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here




// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here




// App Windows Variables
// --------------------------------------------------
// Windows only Sass variables can go here




// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.




// Fonts
// --------------------------------------------------

//@import "roboto";
//@import "noto-sans";
@font-face{font-family:"Sofia Pro";src:url("../assets/fonts/SofiaProMedium.woff") format("woff"),url("../assets/fonts/SofiaProMedium.woff2") format("woff2"),url("../assets/fonts/SofiaProMedium.ttf") format("truetype");font-weight:normal;font-style:normal}
@font-face{font-family:"Sofia Pro";src:url("../assets/fonts/SofiaProBold.woff") format("woff"),url("../assets/fonts/SofiaProBold.woff2") format("woff2"),url("../assets/fonts/SofiaProBold.ttf") format("truetype");font-weight:bold;font-style:normal}
@font-face{font-family:"Sofia Pro";src:url("../assets/fonts/SofiaProLight.woff") format("woff"),url("../assets/fonts/SofiaProLight.woff2") format("woff2"),url("../assets/fonts/SofiaProLight.ttf") format("truetype");font-weight:200;font-style:normal}
:root {
  --ion-font-family: "Sofia Pro, "Helvetica Neue", sans-serif" !important;
  --ion-default-font: "Sofia Pro", "Helvetica Neue", sans-serif, !important;
  html.ios {
    --ion-default-font: "Sofia Pro", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
  }
  html.md {
    --ion-default-font: "Sofia Pro", "Roboto", "Helvetica Neue", sans-serif;
  }
}

$logo-width: 8%;
$logo-height: 31px;
$header-height: 65px;
$header-inset: 34px;
$header-inset-narrow: 8px;
$sub-nav-height: 49px;
$client-header-height: 50px;
$main-content-width: 100% - $logo-width - 3%;
$main-content-height: 90%;
$footer-height: 50px;
$bottom-action-height: 95px;
$bottom-action-height-single: 44px;
$bottom-action-row-height: 46px;
$color-pill-height: 180px;
$color-tile-height: 160px;

$base-font-size: 16px;
$header-font-size: 16px;
$action-sheet-font-size: 18px;
$color-grid-font-size: 10px;
$color-grid-small-font-size: 12px;
$color-grid-med-font-size: 14px;
$color-grid-large-font-size: 16px;

$line-gray: #ddd;
$separator-color: #555555;
$logo-gray: #70848f;
$logo-silver: silver;
$label_color: #888888;
$clickable_color: magenta;
$disabled-color: #cccccc;
$selected-border-color: #F9BBC6; // same for light and dark modes
$special-text-color: #C0365f;

// default light theme colors
$theme-light-bg-color: #ffffff;
$theme-light-icon-color: #000000;
$theme-light-icon-active-color: #C0365f;
$theme-light-input-bg-color: #e0e0e0;
$theme-light-input-text-color: #202020; // not yet used
$theme-light-active-color: #F9BBC6;
$theme-light-font-color: #000000;
$theme-light-highlight-color: #111111;
$theme-light-subtitle-color: #838383;
$theme-light-border-color: #cccccc;
$theme-light-disabled-color: #cccccc;
$theme-light-alt-disabled-color: #555555;
$theme-light-warn-color: #ac5b24;
$theme-light-header-bg: #FEEFF2;
$theme-light-nav-bg: #000000;
$theme-light-nav-font-color: #000000;
$theme-light-alert-bg: #F8F8F8;

// dark theme colors
$theme-dark-bg-color: #000000;
$theme-dark-modal-color: #333333;
$theme-dark-icon-color: #f0f0f0;
$theme-dark-icon-active-color: #F9BBC6;
$theme-dark-input-bg-color: #666666;
$theme-dark-input-text-color: #f1f1f1;
$theme-dark-active-color: #F9BBC6;
$theme-dark-font-color: #c7c7c7;
$theme-dark-highlight-color: #e0e0e0;
$theme-dark-subtitle-color: #818181;
$theme-dark-border-color: #a0a0a0;
$theme-dark-disabled-color: #555555;
$theme-dark-warn-color: #e57c17;
$theme-dark-header-bg: #C0365F;
$theme-dark-nav-bg: #393939;
$theme-dark-nav-font-color: #ffffff;
$theme-dark-alert-bg: #333333;
$theme-dark-alert-button: #555555;
$theme-dark-slider-bar-color: #8a8a94;
